<template>
  <product title="Amazfit快乐鸭儿童健康手表"
    :swiper-data="swiperData"
    :slogan="slogan"
    price="799"
    :colors="colors"
    :relatived-products="relativedProducts"
    :detail-images="detailImages"
    :param-images="paramImages"
    :lastSwiper="true"
    :lastSwiperData="lastSwiperData"
    :video="video"
    buy-link="https://item.jd.com/100013144253.html"
  ></product>
</template>

<script>
import Product from '../Product'

export default {
  name: 'happya',
  components: {
    Product
  },
  data () {
    return {
      slogan: '近视预防提醒 | 中小学体育达标 | 多维健康监测 | 高清视频通话',
      colors: [
        '天真蓝',
        '朝霞粉'
      ],
      video: {
        url: 'https://site-cdn.huami.com/files/amazfit/cn/Hollywood/full_oLf9WDQ1Pz.mp4'
      },
      swiperData: [
        [
          'https://site-cdn.huami.com/files/amazfit/cn/Hollywood/1_pro_01.png',
          'https://site-cdn.huami.com/files/amazfit/cn/Hollywood/1_pro_02.png',
          'https://site-cdn.huami.com/files/amazfit/cn/Hollywood/1_pro_03.png',
          'https://site-cdn.huami.com/files/amazfit/cn/Hollywood/1_pro_04.png'
        ],
        [
          'https://site-cdn.huami.com/files/amazfit/cn/Hollywood/2_pro_01.png',
          'https://site-cdn.huami.com/files/amazfit/cn/Hollywood/2_pro_02.png',
          'https://site-cdn.huami.com/files/amazfit/cn/Hollywood/2_pro_03.png',
          'https://site-cdn.huami.com/files/amazfit/cn/Hollywood/2_pro_04.png'
        ]
      ],
      relativedProducts: [
      ],
      detailImages: [
        'https://site-cdn.huami.com/files/amazfit/cn/Hollywood/bg_1.png',
        'https://site-cdn.huami.com/files/amazfit/cn/Hollywood/bg_2.png',
        'https://site-cdn.huami.com/files/amazfit/cn/Hollywood/bg_33.png',
        'https://site-cdn.huami.com/files/amazfit/cn/Hollywood/bg_44.png',
        'https://site-cdn.huami.com/files/amazfit/cn/Hollywood/bg_5.png',
        'https://site-cdn.huami.com/files/amazfit/cn/Hollywood/bg_5-1.png',
        'https://site-cdn.huami.com/files/amazfit/cn/Hollywood/bg_5-22.png',
        'https://site-cdn.huami.com/files/amazfit/cn/Hollywood/bg_5-3.png',
        'https://site-cdn.huami.com/files/amazfit/cn/Hollywood/bg_66.png',
        'https://site-cdn.huami.com/files/amazfit/cn/Hollywood/bg_77.png',
        'https://site-cdn.huami.com/files/amazfit/cn/Hollywood/bg_88.png',
        'https://site-cdn.huami.com/files/amazfit/cn/Hollywood/bg_99.png',
        'https://site-cdn.huami.com/files/amazfit/cn/Hollywood/bg_10.png',
        'https://site-cdn.huami.com/files/amazfit/cn/Hollywood/bg_11_61151e698e1f6.png',
        'https://site-cdn.huami.com/files/amazfit/cn/Hollywood/bg_12.png'
      ],
      paramImages: [
        'https://site-cdn.huami.com/files/amazfit/cn/Hollywood/bg_14_61151e6a635ed.png'
      ],
      lastSwiperData: [
        'https://site-cdn.huami.com/files/amazfit/cn/Hollywood/m1.png',
        'https://site-cdn.huami.com/files/amazfit/cn/Hollywood/m2.png',
        'https://site-cdn.huami.com/files/amazfit/cn/Hollywood/m3.png',
        'https://site-cdn.huami.com/files/amazfit/cn/Hollywood/m4.png',
        'https://site-cdn.huami.com/files/amazfit/cn/Hollywood/m5.png',
        'https://site-cdn.huami.com/files/amazfit/cn/Hollywood/m6.png',
        'https://site-cdn.huami.com/files/amazfit/cn/Hollywood/m7.png',
        'https://site-cdn.huami.com/files/amazfit/cn/Hollywood/m8.png',
        'https://site-cdn.huami.com/files/amazfit/cn/Hollywood/m9.png',
        'https://site-cdn.huami.com/files/amazfit/cn/Hollywood/m10.png',
        'https://site-cdn.huami.com/files/amazfit/cn/Hollywood/m11.png',
        'https://site-cdn.huami.com/files/amazfit/cn/Hollywood/m12.png'
      ]
    }
  },
  methods: {
  }
}
</script>

<style>
</style>
